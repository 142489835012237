import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer'
import Qualification from '@/mixins/Qualification.js'

export default {
	mixins: [Qualification],
	methods: {
		getStallionSettings(horse_id) {
			return this.$storage.db.t('semence_stallion')
			.then(table => {
				return table.where({
					semencestallion_horse: parseInt(horse_id),
				})
			})
			.then(col => {
				return col.toArray()
			})
			.then(res => {
				return res.length > 0 ? res[0] : null
			})
		},

		createStallionSettings(horse_id, couleur_id, stockage_id, prestockage_id) {
			return this.$storage.db.t('semence_stallion')
                .then(table => {
                    return table.add({
                    	semencestallion_id: Common.getNegativeId(),
                        semencestallion_horse: horse_id,
                        semencestallion_couleur: couleur_id,
                        semencestallion_stockage: stockage_id,
                        semencestallion_prestockage: prestockage_id,
                        semencestallion_licencekey: Constants.USER_LICENCE_KEY,
                        semencestallion_valide: 1
                    })
                })
		},

		updateStallionSettings(setting_id, couleur_id, stockage_id, prestockage_id) {
			return this.$storage.db.t('semence_stallion')
                .then(table => {
                    return table.update(parseInt(setting_id), {
                        semencestallion_couleur: couleur_id,
                        semencestallion_stockage: stockage_id,
                        semencestallion_prestockage: prestockage_id
                    })
                })
		},

		addDilueur(label) {
			return this.$storage.db.t('semence_dilueur')
                .then(table => {
                    return table.add({
                    	semencedilueur_id: Common.getNegativeId(),
                        semencedilueur_label: label,
                        semencedilueur_licencekey: Constants.USER_LICENCE_KEY,
                        semencedilueur_valide: 1
                    })
                })
		},

		getAllDilueurs() {
			return this.$storage.db.t('semence_dilueur')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
				    return col.toArray()
				})
		},

		updateDilueur(dilueur_id, label) {
			return this.$storage.db.t('semence_dilueur')
                .then(table => {
                    return table.update(parseInt(dilueur_id), {
                        semencedilueur_label: label
                    })
                })
		},

		getAllTransporteurs() {
			return this.$storage.db.t('semence_transporteurs')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
				    return col.toArray()
				})
		},

		async createSemenceLots(lots) {
			await Common.asyncForEach(lots, async (lot) => {
			    await this.$storage.db.t('semence_lot')
			    .then(table => {
			        return table.add({
			            semencelot_id: Common.getNegativeId(),
			            semencelot_valide: 1,
			            semencelot_date: lot.semencelot_date,
						semencelot_quantieme: '',
						semencelot_volume: lot.semencelot_volume,
						semencelot_comportement: lot.semencelot_comportement,
						semencelot_observation: lot.semencelot_observation,
						semencelot_commentaire: lot.semencelot_commentaire,
						semencelot_horse: lot.semencelot_horse,
						semencelot_dilueur: lot.semencelot_dilueur,
						semencelot_couleur: lot.semencelot_couleur,
						semencelot_acte: lot.semencelot_acte,
						semencelot_qte: lot.semencelot_qte
			        })
			    })
			})
		},

		createSemenceDose(dose) {
		    return this.$storage.db.t('semence_dose')
		    .then(table => {
		        return table.add({
		            semencedose_id: Common.getNegativeId(),
		            semencedose_valide: 1,
		            semencedose_date: dose.semencedose_date,
		            semencedose_horse: dose.semencedose_horse,
		            semencedose_qte: dose.semencedose_qte,
		            semencedose_volume: dose.semencedose_volume,
		            semencedose_acte: dose.semencedose_acte
		        })
		    })
		},

		async getNotInventoried() {
			const url = Constants.CONGELATION_NOT_INVENTORIED_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getNotInventoried", url)
			.catch(e => {
                console.error("CongelationMixin::getNotInventoried => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async destroyLotsNotInventoried(lots_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_NOT_INVENTORIED_ID_URL, {lots_ids: lots_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("CongelationMixin::destroyLotsNotInventoried", url)
			.catch(e => {
                console.error("CongelationMixin::destroyLotsNotInventoried => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async stockLots(lots, prestock) {
			const url = Constants.CONGELATION_STOCK_LOTS_URL+'?licence_key='+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("CongelationMixin::stockLots", url, {lots, prestock}, false)
				.catch(e => {
					console.error("CongelationMixin::stockLots => ", e)
					return null
				})
				.then(res => res.retour)
		},

		async getPrestockageLots() {
			const url = Constants.CONGELATION_PRESTOCKAGE_LOTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getPrestockageLots", url)
			.catch(e => {
                console.error("CongelationMixin::getPrestockageLots => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async deplacementLots(lots) {
			const url = Constants.CONGELATION_DEPLACEMENT_LOTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::deplacementLots", url, {lots}, false)
			.catch(e => {
                console.error("CongelationMixin::deplacementLots => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async createControlLot(lot_id, params) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_CONTROL_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::createControlLot", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::createControlLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editControlLot(control_id, params) {
			const url = Common.constructRoute(Constants.CONGELATION_CONTROL_SEMENCE_ID_URL, {control_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editControlLot", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::editControlLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getControlByLot(lot_id) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_CONTROL_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getControlByLot", url)
			.catch(e => {
                console.error("CongelationMixin::getControlByLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

        async getActeTypeTest() {
            const actes_type_test = [
                "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON",
                "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON",
                "TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE",
                "TEST_DOURINE",
                "TEST_MORVE"
            ]

            const actes_type = await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where('actestype_code').anyOf(actes_type_test).toArray()
            })

            for(let i in actes_type) {
                actes_type[i].actestype_label = this.getTrad('actes_type.'+actes_type[i].actestype_code)
            }

            return actes_type
        },

		async getAllProtocoles() {
			const url = Constants.CONGELATION_PROTOCOLES_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getAllProtocoles", url)
			.catch(e => {
                console.error("CongelationMixin::getAllProtocoles => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async createProtocole(form, actes_types) {
			const url = Constants.CONGELATION_PROTOCOLES_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::createProtocole", url, {...form, actes_types}, false)
			.catch(e => {
                console.error("CongelationMixin::addProtocole => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editProtocole(protocole_id, form, actes_types) {
			const url = Common.constructRoute(Constants.CONGELATION_PROTOCOLES_ID_URL, {protocole_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editProtocole", url, {...form, actes_types}, false)
			.catch(e => {
                console.error("CongelationMixin::editProtocole => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getCampagnesByHorse(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getCampagnesByHorse", url)
			.catch(e => {
                console.error("CongelationMixin::getCampagnesByHorse => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async createCampagne(horse_id, form, protocoles) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::createCampagne", url, {...form, protocoles}, false)
			.catch(e => {
                console.error("CongelationMixin::createCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editCampagne(horse_id, campagne_id, form, protocoles) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_ID_URL, {horse_id, campagne_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editCampagne", url, {...form, protocoles}, false)
			.catch(e => {
                console.error("CongelationMixin::editCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async loadCentreCollecte() {
			const type = await this.$storage.db.t('qualification')
				.then(table => {
					return table.where({
						qualification_code: 'centre_collecte'
					})
				})
				.then(col => {
					return col.first()
				})

			const qualif_link = await this.$storage.db.t('qualification_link')
			    .then(table => {
			    	return table.where({
			    		qualificationlink_qualification: type.qualification_id,
			    	})
			    }).then(col => {
					return col.toArray()
				})

			const tiers_ids = qualif_link.map(qualif => qualif.qualificationlink_fk)

			const tiers = await this.$storage.db.t('tiers')
				.then(table => {
					return table
					.where('tiers_id').anyOf(tiers_ids)
					.and(tier => tier.tiers_archive == 0)
				})
				.then(col => {
					return col.transform(new TierTransformer('light'))
				})

			await Common.asyncForEach(tiers, async (tier) => {
				const q = await this.getQualificationLink(type.qualification_id, 'tiers', tier.tiers_id)
				tier.numero = q.qualification_reponse_data.length == 1 ? q.qualification_reponse_data[0].reponse_data[0] : ''
			})

			return tiers
		},

		async getCampagne(horse_id, campagne_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_ID_URL, {horse_id, campagne_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getCampagne", url)
			.catch(e => {
                console.error("CongelationMixin::getCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getLocalisationsByLot(lot_id) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_LOCALISATIONS_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getLocalisationsByLot", url)
			.catch(e => {
                console.error("CongelationMixin::getLocalisationsByLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getEmplacementsByLot(lot_id) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_EMPLACEMENTS_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getEmplacementsByLot", url)
			.catch(e => {
                console.error("CongelationMixin::getEmplacementsByLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async closeCampagne(campagne) {
			let params_url = {
				horse_id: campagne.campagne_horse,
				campagne_id: campagne.campagne_id
			}
			let params = {
				delete_acte: campagne.delete_acte,
				campagne_end: campagne.campagne_end
			}

			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_CLOSE_URL, params_url)+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::closeCampagne", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::closeCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async printCourrierCampagne(horse_id, campagne_id, model_id, campagneprotocole_id, protocole_label, with_notes) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_PRINT_URL, {horse_id, campagne_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::printCourrierCampagne", url, {model_id, campagneprotocole_id, with_notes}, false)
			.catch(e => {
                console.error("CongelationMixin::printCourrierCampagne => ", e)
                return null
			})

            if(result) Common.base64ToPdf(result, protocole_label + ".pdf")
            return []
		},

		async deleteCampagne(horse_id, campagne_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_ID_URL, {horse_id, campagne_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("CongelationMixin::deleteCampagne", url)
			.catch(e => {
                console.error("CongelationMixin::deleteCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStocksByStallion(horse_id, params) {
			let url = Common.constructRoute(Constants.CONGELATION_HORSE_STOCKS_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			for(let i in params) {
				if(params[i])
					url += '&'+i+"="+params[i]
			}

			const result = await this.$request.request_get_api("CongelationMixin::getStocksByStallion", url)
			.catch(e => {
                console.error("CongelationMixin::getStocksByStallion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getHorseStockTiers(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_STOCKS_TIERS_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getHorseStockTiers", url)
			.catch(e => {
                console.error("CongelationMixin::getHorseStockTiers => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getHorseStockNormes(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_STOCKS_NORMES_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getHorseStockNormes", url)
			.catch(e => {
                console.error("CongelationMixin::getHorseStockNormes => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editLot(lot_id, params) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_ID_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editLot", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::editLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async destroyLots(lots_ids, commentaire) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_DESTROY_URL, {lots_ids: lots_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::destroyLots", url, {commentaire}, false)
			.catch(e => {
                console.error("CongelationMixin::destroyLots => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async assignLots(lots_ids, tiers_id, date) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_ASSIGN_URL, {lots_ids: lots_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::assignLots", url, {tiers_id, date}, false)
			.catch(e => {
                console.error("CongelationMixin::assignLots => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async inventaireEmplacement(emplacement_id, old_qte, qte, commentaire) {
			const url = Common.constructRoute(Constants.CONGELATION_EMPLACEMENT_STOCK_URL, {emplacement_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editLot", url, {old_qte, qte, commentaire}, false)
			.catch(e => {
                console.error("CongelationMixin::editLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async destroyEmplacements(emplacement_ids, commentaire) {
			const url = Common.constructRoute(Constants.CONGELATION_EMPLACEMENT_DESTROY_URL, {emplacement_ids: emplacement_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::destroyEmplacements", url, {commentaire}, false)
			.catch(e => {
                console.error("CongelationMixin::destroyEmplacements => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async assignEmplacements(emplacement_ids, tiers_id, date) {
			const url = Common.constructRoute(Constants.CONGELATION_EMPLACEMENT_ASSIGN_URL, {emplacement_ids: emplacement_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::assignEmplacements", url, {tiers_id, date}, false)
			.catch(e => {
                console.error("CongelationMixin::assignEmplacements => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getFluxEmplacement(emplacement_id) {
			const url = Common.constructRoute(Constants.CONGELATION_EMPLACEMENT_FLUX_URL, {emplacement_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getFluxEmplacement", url)
			.catch(e => {
                console.error("CongelationMixin::getFluxEmplacement => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async splitEmplacement(emplacement_id, qte, stockage_id, date, commentaire) {
			const url = Common.constructRoute(Constants.CONGELATION_EMPLACEMENT_SPLIT_URL, {emplacement_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::splitEmplacement", url, {qte, stockage_id, date, commentaire}, false)
			.catch(e => {
                console.error("CongelationMixin::splitEmplacement => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async addEmplacement(lot_id, qte, commentaire, tiers_id, stockage_id, date, norme_lock, from_tiers) {
			const url = Common.constructRoute(Constants.CONGELATION_LOT_EMPLACEMENTS_URL, {lot_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::addEmplacement", url, {qte, commentaire, tiers_id, stockage_id, date, norme_lock, from_tiers}, false)
			.catch(e => {
                console.error("CongelationMixin::addEmplacement => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStallionPlanCuve(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_PLAN_CUVE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getStallionPlanCuve", url)
			.catch(e => {
                console.error("CongelationMixin::getStallionPlanCuve => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStallionPlanCuveDetail(horse_id, localisation_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_PLAN_CUVE_DETAIL_URL, {horse_id, localisation_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getStallionPlanCuveDetail", url)
			.catch(e => {
                console.error("CongelationMixin::getStallionPlanCuveDetail => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getLotsByStallion(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_LOTS, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getLotsByStallion", url)
			.catch(e => {
                console.error("CongelationMixin::getLotsByStallion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async createLotByStallion(horse_id, params) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_LOTS, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::createLotByStallion", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::createLotByStallion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async addExpedition(params) {
			const url = Constants.CONGELATION_EXPEDITIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::addExpedition", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::addExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editExpedition(expedition_id, params) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ID_URL, {expedition_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::editExpedition", url, params, false)
			.catch(e => {
                console.error("CongelationMixin::editExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async addExpeditionArticle(expedition_id, articles) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITION_ARTICLES_URL, {expedition_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::addExpeditionArticle", url, {articles}, false)
			.catch(e => {
                console.error("CongelationMixin::addExpeditionArticle => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getExpeditions(horse_id, status, type_date, start_date, end_date) {
			let url = Constants.CONGELATION_EXPEDITIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY+'&status='+status
			if(horse_id) {
				url += '&horse_id='+horse_id
			}
			if(type_date) {
				url += '&type_date='+type_date.code+'&start_date='+start_date.toDateInputValue()+'&end_date='+end_date.toDateInputValue()
			}
			const result = await this.$request.request_get_api("CongelationMixin::getExpeditions", url)
			.catch(e => {
                console.error("CongelationMixin::getExpeditions => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async cancelExpedition(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ID_URL, {expedition_id: expeditions_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("CongelationMixin::cancelExpedition", url)
			.catch(e => {
                console.error("CongelationMixin::cancelExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async differeExpedition(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'differe'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::differeExpedition", url, {}, false)
			.catch(e => {
                console.error("CongelationMixin::differeExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async prepareExpeditions(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'prepare'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::prepareExpeditions", url)
			.catch(e => {
                console.error("CongelationMixin::prepareExpeditions => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async saveProtocoleOrder(orders) {
			const url = Constants.CONGELATION_PROTOCOLES_ORDER_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::saverotocoleOrder", url, {orders}, false)
			.catch(e => {
                console.error("CongelationMixin::saveProtocoleOrder => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async pretExpedition(expeditions_ids, expeditions, localisation_id) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'pret_expedition'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::pretExpedition", url, {expeditions, localisation_id}, false)
			.catch(e => {
                console.error("CongelationMixin::pretExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getLotsSuggestion(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'lots_suggestion'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getLotsSuggestion", url)
			.catch(e => {
                console.error("CongelationMixin::getLotsSuggestion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStallionLotsProprietaires(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_PROPRIO_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getStallionLotsProprietaires", url)
			.catch(e => {
                console.error("CongelationMixin::getStallionLotsProprietaires => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getLotsAvailable(stallion_id, demandeur_id, protocole_id, expedition_id) {
			const url = Constants.CONGELATION_LOTS_AVAILABLE_URL+"?licence_key="+Constants.USER_LICENCE_KEY
						+"&stallion_id="+stallion_id+"&demandeur_id="+demandeur_id+"&protocole_id="+protocole_id+"&expedition_id="+expedition_id
			const result = await this.$request.request_get_api("CongelationMixin::getLotsAvailable", url)
			.catch(e => {
                console.error("CongelationMixin::getLotsAvailable => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getReintegrationStocks(localisation_id) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_REINTEGR_URL, {localisation_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getReintegrationStocks", url)
			.catch(e => {
                console.error("CongelationMixin::getReintegrationStocks => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async reintegreStocks(localisation_id, emplacements) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_REINTEGR_URL, {localisation_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::reintegreStocks", url, {emplacements}, false)
			.catch(e => {
                console.error("CongelationMixin::reintegreStocks => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async demandeEnlevement(expeditions_ids, transporteur_id, model_id) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_DND_ENLEV_URL, {expeditions_ids})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::demandeEnlevement", url, {transporteur_id, model_id}, false)
			.catch(e => {
                console.error("CongelationMixin::demandeEnlevement => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async confirmExpeditions(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'confirm'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::prepareExpeditions", url)
			.catch(e => {
                console.error("CongelationMixin::prepareExpeditions => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async printExpeditionCourrier(expeditions_ids, type, model_id, model_name) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_PRINT_URL, {expeditions_ids, type})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::printExpeditionCourrier", url, {model_id}, false)
			.catch(e => {
                console.error("CongelationMixin::printExpeditionCourrier => ", e)
                return null
			})

            if(result) Common.base64ToPdf(result, model_name + ".pdf")
            return []
		},

		async deleteFloors(floors_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_FLOORS_IDS_URL, {floors_ids: floors_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("CongelationMixin::deleteFloors", url)
			.catch(e => {
                console.error("CongelationMixin::deleteFloors => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStatistiqueProduction(tiers_ids, start, end) {
			const url = Constants.CONGELATION_STAT_PRODUCTION_URL+"?licence_key="+Constants.USER_LICENCE_KEY+'&tiers_ids='+tiers_ids+'&start='+start+'&end='+end
			const result = await this.$request.request_get_api("CongelationMixin::getStatistiqueProduction", url)
			.catch(e => {
                console.error("CongelationMixin::getStatistiqueProduction => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStatistiqueProductionLot(tiers_ids, start, end) {
			const url = Constants.CONGELATION_STAT_PRODUCTION_LOT_URL+"?licence_key="+Constants.USER_LICENCE_KEY+'&tiers_ids='+tiers_ids+'&start='+start+'&end='+end
			const result = await this.$request.request_get_api("CongelationMixin::getStatistiqueProductionLot", url)
			.catch(e => {
                console.error("CongelationMixin::getStatistiqueProductionLot => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStocksByTiers(tiers_id) {
			const url = Common.constructRoute(Constants.CONGELATION_TIERS_STOCKS_URL, {tiers_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getStocksByTiers", url)
			.catch(e => {
                console.error("CongelationMixin::getStocksByTiers => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getLotsCampagneByStallion(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_HORSE_CAMPAGNE_LOTS_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getLotsCampagneByStallion", url)
			.catch(e => {
                console.error("CongelationMixin::getLotsCampagneByStallion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async checkCampagne(horse_id, date) {
			const url = Common.constructRoute(Constants.CONGELATION_CHECK_CAMPAGNE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY+"&date="+date
			const result = await this.$request.request_get_api("CongelationMixin::checkCampagne", url)
			.catch(e => {
                console.error("CongelationMixin::checkCampagne => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getCuvesShipped() {
			const url = Constants.CONGELATION_CUVE_SHIPPED_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getCuvesShipped", url)
			.catch(e => {
                console.error("CongelationMixin::getCuvesShipped => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async confirmCuveRetour(cuves_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_CUVE_RETOUR_URL, {cuves_ids})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::confirmCuveRetour", url)
			.catch(e => {
                console.error("CongelationMixin::confirmCuveRetour => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async sendRetourCuve(cuve_id, model_id) {
			const url = Common.constructRoute(Constants.CONGELATION_CUVE_RETOUR_PRINT_URL, {cuve_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::sendRetourCuve", url, {model_id}, false)
			.catch(e => {
                console.error("CongelationMixin::sendRetourCuve => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getStallionNote(horse_id) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_NOTE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CongelationMixin::getStallionNote", url)
			.catch(e => {
                console.error("CongelationMixin::getStallionNote => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async addStallionNote(horse_id, form) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_NOTE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::addStallionNote", url, form, false)
			.catch(e => {
                console.error("CongelationMixin::addStallionNote => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async editStallionNote(horse_id, note_id, form) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_NOTE_ID_URL, {horse_id, note_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin: editStallionNote", url, form, false)
			.catch(e => {
                console.error("CongelationMixin: editStallionNote => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async printSortieStocks(emplacements, destinataire_id, cuve_id, model_id, model_name) {
			const url = Constants.CONGELATION_SORTIE_STOCK_PRINT_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::printSortieStocks", url, {emplacements, destinataire_id, cuve_id, model_id}, false)
			.catch(e => {
                console.error("CongelationMixin::printSortieStocks => ", e)
                return null
			})

            if(result) Common.base64ToPdf(result, model_name + ".pdf")
            return []
		},

		async deleteStallionNote(horse_id, note_id) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_NOTE_ID_URL, {horse_id, note_id})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_delete_api("CongelationMixin: deleteStallionNote", url, {}, false)
			.catch(e => {
                console.error("CongelationMixin: deleteStallionNote => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getDosesByStallion(horse_id, start_date, end_date) {
			const url = Common.constructRoute(Constants.CONGELATION_STALLION_DOSE_URL, {horse_id})+"?licence_key="+Constants.USER_LICENCE_KEY+'&start_date='+start_date.toDateInputValue()+'&end_date='+end_date.toDateInputValue()
			const result = await this.$request.request_get_api("CongelationMixin::getDosesByStallion", url)
			.catch(e => {
                console.error("CongelationMixin::getDosesByStallion => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getDoses(start_date, end_date) {
			const url = Constants.CONGELATION_DOSE_URL+"?licence_key="+Constants.USER_LICENCE_KEY+'&start_date='+start_date.toDateInputValue()+'&end_date='+end_date.toDateInputValue()
			const result = await this.$request.request_get_api("CongelationMixin::getDoses", url)
			.catch(e => {
                console.error("CongelationMixin::getDoses => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async getDosesAvailable(expeditions_ids, date) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'doses_available'})+"?licence_key="+Constants.USER_LICENCE_KEY+"&date="+date.toDateInputValue()
			const result = await this.$request.request_get_api("CongelationMixin::getDosesAvailable", url)
			.catch(e => {
                console.error("CongelationMixin::getDosesAvailable => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async pretExpeditionDose(expeditions_ids, expeditions, date) {
			const url = Common.constructRoute(Constants.CONGELATION_EXPEDITIONS_ACTION_URL, {expeditions_ids, action: 'pret_expedition_dose'})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::pretExpedition", url, {expeditions, date}, false)
			.catch(e => {
                console.error("CongelationMixin::pretExpedition => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		async reintegreDoses(expeditions_ids) {
			const url = Common.constructRoute(Constants.CONGELATION_EXP_DOSE_REINTEGR_URL, {expeditions_ids})+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("CongelationMixin::reintegreDoses", url, {}, false)
			.catch(e => {
                console.error("CongelationMixin::reintegreDoses => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},
	}
}